import React from 'react'
import { Link, navigate } from 'gatsby'

import Layout from '../components/Layout'
import FooterBranding from '../components/footer/FooterBranding'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter,  faInstagram } from "@fortawesome/free-brands-svg-icons"

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

// const PermanencesJuridiques = () => (
class PermanencesJuridiques extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chkbox: false,
            objet: 'droits-de-la-famille',
            nom: '',
            prenom: '',
            email: '',
            telephone: '',
            message: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeChk = this.handleChangeChk.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeChk(event) {
        const { chkbox } = this.state;
        console.log(!chkbox);
        this.setState({chkbox: !chkbox});
    }

    handleSelectChange(event) {
        // event.preventDefault;
        console.log(event.target.value);
        this.setState({objet: event.target.value});
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleSubmit(event) {
        // alert('Votre parfum favori est : ' + this.state.value);
        // alert('' + this.state.chkbox);
        const { chkbox } = this.state;
        event.preventDefault();
        const mainState = this.state;

        if( chkbox === false ){
            alert('Veuillez cocher la case de consentement');
            console.log(this.state)
        }else{
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "permanences-juridiques", ...this.state })
                // body: encode({ "form-name": "permanences-juridiques", mainState})
              })
                .then(() => {
                    alert("Success!");
                    navigate('/success');
                })
                .catch(error => alert(error));
        }
        // event.preventDefault();
    }
    
    render() {
        const { nom, prenom, email, objet, telephone, message } = this.state;
        return (
            <Layout>

                <div className="container custom-form">
                    <div className="container--inner">
                    
                        <div className="split-contact">
                            <div className="content-wrapper">
                                <div className="content-block">
                                <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/06/juridique.png" class="img-post" alt=""/>
                                
                                <h2>Suivez-nous</h2>
                                <div className="social">
                                    <ul className="">
                                        <li>
                                            <a href="https://www.facebook.com/AssociationJAIDE/"><FontAwesomeIcon size="2x" icon={faFacebook} /><span>Facebook</span></a>
                                        </li>
                                        <li>
                                            <a href=""><FontAwesomeIcon size="2x" icon={faTwitter} /><span>Twitter</span></a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/p/BoW2DNhHGyq/"><FontAwesomeIcon size="2x" icon={faInstagram} /><span>Instagram</span></a>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="content">
                                    
                                    <h2></h2>
                                    <p></p>
                                </div>
                            </div>
                            <div className="form">
                                <div className="content">
                                <h1>Permanences juridiques</h1>
                                    <p>
                                    Un problème d’accès au droit ?
                                    </p>
                                    <p>
                                    Nous mettons à votre disposition des permanences gratuites et confidentielles assurées par des
                                    professionnels bénévoles.<br/>
                                    Elles s’adressent uniquement aux personnes les plus démunies afin de les informer et/ou
                                    orienter vers un organisme adapté.
                                    </p>
                                </div>
                                <p>Les inscriptions se feront uniquement sur rendez-vous, via le formulaire, ci-dessous :</p>
                                <form  onSubmit={this.handleSubmit}>
                                
                                    <div className="input-wrap"><label htmlFor="">Nom</label><input type="text" class="" name="nom" value={nom} onChange={this.handleChange} placeholder="Nom"/></div>
                                    <div className="input-wrap"><label htmlFor="">Prenom</label><input type="text" class="" name="prenom" value={prenom} onChange={this.handleChange} placeholder="Prenom"/></div>
                                    <div className="input-wrap"><label htmlFor="">Email</label><input type="email" class="" name="email" value={email} onChange={this.handleChange} placeholder="Email"/></div>
                                    <div className="input-wrap"><label htmlFor="">Téléphone</label><input type="text" class="" name="telephone" value={telephone} onChange={this.handleChange} placeholder="Téléphone"/></div>
                                    <div className="input-wrap">
                                    <label htmlFor="">Objet</label>
                                        <select name="objet" value={objet} onChange={this.handleSelectChange}>
                                            <option value="droits-de-la-famille">Droit de la famille</option>
                                            <option value="droits-des-etrangers-et-droit-d'asiles">Droit des étrangers et droit d'asile</option>
                                            <option value="droits-du-travail">Droit du travail</option>
                                            <option value="conflits-lies-au-logement">Conflits liés au logement</option>
                                            <option value="surendettement">Surendettement</option>
                                            <option value="autres-domaines">Autre(s) domaine(s)</option>
                                        </select>

                                    </div>
                                    <div className="input-wrap">
                                        <label htmlFor="">Message</label>
                                        <textarea id="" cols="30" rows="10" name="message" value={message} onChange={this.handleChange}></textarea>
                                    </div>
                                    <div className="input-wrap">
                                    </div>
                                    <label><input type="checkbox" name="checkbox" value="check" defaultChecked={this.state.chkbox} onChange={this.handleChangeChk} />  En cochant cette case, je consens au traitement de mes données personnelles afin d'être recontacté(e) par l'association JAIDE</label>
                                    <div className="input-wrap">
                                    
                                        <input type="submit" class='btn'  value="Envoyer" onclick="if(!this.form.checkbox.checked){alert('You must agree to the terms first.');return false}"/>
                                    </div>

                                </form>
                            </div>
                        
                        </div>

                    </div>
                </div>
                <FooterBranding/>
            </Layout>
        )
    }
}     

export default PermanencesJuridiques
